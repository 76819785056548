import { Link } from '@mui/material';
import { AgaveUser } from '@x99minutos/agave';
import React from 'react';
import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import { useNavigate } from 'react-router-dom';
import logo99 from '../../../images/logo-99minutos.svg';
import logoML from '../../../images/logo__large_plus.png';
import { frontRoutes } from '../../helpers/constants';

export const NavBar = () => {
  const navigate = useNavigate();
  const userMeli = JSON.parse(localStorage.getItem('userMeLi'));

  const logout = () => {
    localStorage.clear();
    navigate(frontRoutes.login);
  };

  return (
    <>
      <Navbar bg="light" variant="light">
        <Container>
          <Navbar.Brand href="/meli/dashboard">
            <img src={logo99} alt="" className="logo-nav"></img>
            <img src={logoML} alt=""></img>
          </Navbar.Brand>
          <Navbar.Collapse className="justify-content-end">
            <AgaveUser
              name={`${userMeli?.name ?? 'usuario'} 99`}
              userOptions={[
                {
                  link: (
                    <Link href="#logout" color="text.primary" onClick={() => logout()}>
                      Cerrar sesión
                    </Link>
                  ),
                },
                // { label: 'Texto ejemplo', onClick: handleClick },
              ]}
            />
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
};
