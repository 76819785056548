const useValidateSession = () => {
  const userMeli = localStorage.getItem('userMeLi');
  const path = window.location.pathname;
  const origin = window.location.origin;

  if ([userMeli].includes(null)) {
    if (path !== '/meli/login') {
      localStorage.clear();
      window.location.href = `${origin}/meli/login`;
    }
    return;
  }

  const jsonUserMeLi = JSON.parse(localStorage.getItem('userMeLi'));

  if (!jsonUserMeLi?.user) {
    localStorage.clear();
    window.location.href = `${origin}/meli/login`;
  }

  const now = new Date();
  const lastEvent = new Date(jsonUserMeLi.lastAction);
  const differenceBetweenDates = now.getTime() - lastEvent.getTime();

  if (differenceBetweenDates > 7200000) {
    if (path !== '/meli/login') {
      localStorage.clear();
      window.location.href = `${origin}/meli/login`;
    }
    return;
  }

  if (path === '/meli/login') {
    window.location.href = `${origin}/meli/dashboard`;
  }
};

export default useValidateSession;
