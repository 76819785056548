import { Button, Chip, MenuItem, Select } from '@mui/material';
import React, { useEffect, useState } from 'react';
import Card from 'react-bootstrap/Card';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Spinner from 'react-bootstrap/Spinner';
import Table from 'react-bootstrap/Table';
import Tooltip from 'react-bootstrap/Tooltip';
import Swal from 'sweetalert2';
import routes from '../../helpers/constants';
import useValidateSession from '../../helpers/validateSession';
import { Guide } from '../modals/Guide';

export const OrdersCreated = () => {
  useValidateSession();

  const userMeli = JSON.parse(localStorage.getItem('userMeLi'));
  const [orders99m, setOrders99m] = useState([]);
  const [showModalPDF, setShowModalPDF] = useState(false);
  const [counter, setCounter] = useState();
  const [loading, setLoading] = useState(false);

  // Estados de paginación
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(25); // Valor por defecto
  const [totalPages, setTotalPages] = useState(1);

  const getOrdersCreated99 = async (page, limit) => {
    setLoading(true);
    try {
      const response = await fetch(`${routes.backOrders99status}?page=${page}&limit=${limit}`, {
        headers: { user: userMeli.user },
        method: 'GET',
      });

      if (response.status === 200) {
        const data = await response.json();
        setOrders99m(data.data);
        setTotalPages(data.totalPages);
      } else {
        const data = await response.json();

        Swal.fire({
          title: `Error`,
          text: `Hubo un problema al obtener ordenes, traceId: ${data.message}`,
          icon: 'error',
          confirmButtonColor: '#85C440',
        });
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
    }
  };

  const openModalGuide = (counter99) => {
    setCounter(counter99);
    setShowModalPDF(true);
  };

  useEffect(() => {
    getOrdersCreated99(page, limit);
  }, [page, limit]);

  if (loading) {
    return (
      <div className="justify-content-md-center medio">
        <Spinner animation="border" variant="success" />
      </div>
    );
  }

  const countryUrlML = (country) => {
    switch (country) {
      case 'MEX':
        return 'mx';
      case 'CHL':
        return 'cl';
      case 'COL':
        return 'co';
      case 'PER':
        return 'pe';
      default:
        return country;
    }
  };

  return (
    <div>
      <Guide setShowModalPDF={setShowModalPDF} showModalPDF={showModalPDF} counter={counter} />
      <Card.Title>Seguimiento a órdenes</Card.Title>
      <Card.Text>Da seguimiento a las órdenes generadas con 99minutos.</Card.Text>

      {/* Selector de cantidad de registros */}
      <div className="d-flex justify-content-end mb-3 align-items-center">
        <label className="me-2">Mostrar:</label>
        <Select value={limit} onChange={(e) => setLimit(e.target.value)} size="small">
          <MenuItem value={5}>5</MenuItem>
          <MenuItem value={10}>10</MenuItem>
          <MenuItem value={25}>25</MenuItem>
          <MenuItem value={50}>50</MenuItem>
          <MenuItem value={100}>100</MenuItem>
        </Select>
      </div>

      <Table className="table table-striped">
        <thead>
          <tr style={{ textAlign: 'center' }}>
            <th># Venta MeLi</th>
            <th>Guía 99minutos</th>
            <th>Estatus Mercado Libre</th>
            <th>Estatus 99minutos</th>
            <th>Fecha de creación</th>
            <th>Acciones</th>
          </tr>
        </thead>
        <tbody>
          {orders99m.map((order) => {
            const date = new Date(order.createdAt);
            const formattedDate = `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;

            return (
              <tr key={order._id} style={{ textAlign: 'center' }}>
                <td>
                  <a
                    href={`https://www.mercadolibre.com.${countryUrlML(userMeli.country)}/ventas/${order.storeOrderId}/detalle`}
                    target="_blank"
                    rel="noreferrer"
                    style={{ color: '#be9a00', fontWeight: 'bold' }}
                  >
                    {order.storeOrderId}
                  </a>
                </td>

                <td>
                  <a
                    href={`https://tracking.99minutos.com/search/${order.counter}`}
                    target="_blank"
                    rel="noreferrer"
                    style={{ color: '#85c440', fontWeight: 'bold' }}
                  >
                    {order.counter}
                  </a>
                </td>

                <td>
                  <Chip label={order.statusMeli} />
                </td>

                <td>
                  <Chip label={order.statusNameES} />
                </td>

                <td>{formattedDate}</td>
                <td>
                  <OverlayTrigger placement="top" overlay={<Tooltip>Ver seguimiento</Tooltip>}>
                    <Button
                      variant="contained"
                      onClick={() =>
                        window.open(`https://tracking.99minutos.com/search/${order.counter}`)
                      }
                    >
                      <i className="fas fa-route"></i>
                    </Button>
                  </OverlayTrigger>{' '}
                  <OverlayTrigger
                    placement="top"
                    overlay={<Tooltip>Descargar guía 99minutos</Tooltip>}
                  >
                    <Button variant="contained" onClick={() => openModalGuide(order.counter)}>
                      <i className="fas fa-file-download"></i>
                    </Button>
                  </OverlayTrigger>
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>

      {/* Paginación */}
      <div className="d-flex justify-content-center mt-3">
        <Button variant="contained" disabled={page === 1} onClick={() => setPage(page - 1)}>
          Anterior
        </Button>
        <span className="mx-3">
          Página {page} de {totalPages}
        </span>
        <Button variant="contained" disabled={page >= totalPages} onClick={() => setPage(page + 1)}>
          Siguiente
        </Button>
      </div>
    </div>
  );
};
