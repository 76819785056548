import { Button } from '@mui/material';
import axios from 'axios';
import { Formik } from 'formik';
import React, { useState } from 'react';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Navbar from 'react-bootstrap/Navbar';
import Spinner from 'react-bootstrap/Spinner';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import icon99 from '../../images/icon99.svg';
import logo99 from '../../images/logo-99minutos.svg';
import logoML from '../../images/logo__large_plus.png';
import routes, { frontRoutes } from '../helpers/constants';
import useValidateSession from '../helpers/validateSession';

function Login() {
  useValidateSession();

  const navigate = useNavigate();

  const [showLoader, setShowLoader] = useState(false);
  const MySwal = withReactContent(Swal);

  const search = window.location.search;
  let code = null;

  if (search !== '') {
    const split = search.split('&');
    const params = split[0].split('=');
    code = params[1];
  }

  return (
    <>
      <Navbar bg="light" variant="light">
        <Container>
          <Navbar.Brand href="/meli/login">
            <img src={logo99} alt="" className="logo-nav"></img>
            <img src={logoML} alt=""></img>
          </Navbar.Brand>
        </Container>
      </Navbar>
      <main className="py-4">
        <div className="container h-100">
          <div className="d-flex justify-content-center h-100">
            <div className="user_card">
              <div className="d-flex justify-content-center">
                <div className="brand_logo_container">
                  <img src={icon99} className="brand_logo" alt="Logo"></img>
                </div>
              </div>
              <div className="d-flex justify-content-center">
                <h1 className="text-18">Iniciar Sesión</h1>
              </div>
              <div className="d-flex justify-content-center form_container">
                <Formik
                  // validationSchema={schema}
                  onSubmit={async (values) => {
                    setShowLoader(true);
                    await new Promise((r) => setTimeout(r, 500));
                    axios
                      .post(routes.backMeliLogin, {
                        email: values.email,
                        password: values.password,
                        code: code,
                      })
                      .then((res) => {
                        localStorage.setItem(
                          'userMeLi',
                          JSON.stringify({
                            user: res.data.user,
                            email: res.data.email,
                            name: res.data.name,
                            country: res.data.country,
                            lastAction: Date(),
                          }),
                        );

                        navigate(frontRoutes.dashboard);
                      })
                      .catch((err) => {
                        let message = '';

                        // Is string
                        if (typeof err.response.data.message === 'string') {
                          message = err.response.data.message;
                        } else if (typeof err.response.data.message === 'object') {
                          message = err.response.data.message.message;
                        } else {
                          message = 'Error al iniciar sesión, por favor intente de nuevo';
                        }

                        MySwal.fire({
                          icon: 'error',
                          title: message,
                          showConfirmButton: true,
                          // timer: 2000
                        });
                        setShowLoader(false);
                      });
                  }}
                  initialValues={{
                    email: '',
                    password: '',
                  }}
                >
                  {({
                    handleSubmit,
                    handleChange,
                    handleBlur,
                    values,
                    touched,
                    isValid,
                    errors,
                  }) => (
                    <Form onSubmit={handleSubmit} className="user-form">
                      <div className="input-group mb-3">
                        <div className="input-group-append">
                          <span className="input-group-text">
                            <i className="fas fa-envelope"></i>
                          </span>
                        </div>
                        <Form.Control
                          type="email"
                          placeholder="Correo"
                          name="email"
                          defaultValue={values.email}
                          onChange={handleChange}
                          disabled={showLoader}
                          required
                        />
                      </div>
                      <div className="input-group mb-3">
                        <div className="input-group-append">
                          <span className="input-group-text">
                            <i className="fas fa-key"></i>
                          </span>
                        </div>
                        <Form.Control
                          type="password"
                          placeholder="Contraseña"
                          name="password"
                          defaultValue={values.password}
                          onChange={handleChange}
                          disabled={showLoader}
                          required
                        />
                      </div>
                      <div className="d-flex justify-content-center mt-3 login_container">
                        <Button variant="contained" type="submit">
                          {showLoader ? (
                            <Spinner animation="border" variant="light" size="m" />
                          ) : (
                            'Ingresar'
                          )}
                        </Button>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
}

export default Login;
