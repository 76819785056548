import { Navigate, Route, Routes } from 'react-router-dom';
import Login from './pages/Auth/Login';
import { Dashboard } from './pages/MercadoLibre/Dashboard';
import { OrderML } from './pages/MercadoLibre/Orders/Order';
import { OrdersCreated } from './pages/MercadoLibre/Orders/OrdersCreated';

import './App.css';

function App() {
  // validateSession();
  // const userMeli = JSON.parse(localStorage.getItem('userMeLi'));
  // if (userMeli === null) {
  //   return window.location.href = routes.meliLogin;
  // }

  return (
    <div>
      <Routes>
        {/* <Route path="/" element={<Home />}></Route> */}
        <Route path="/" element={<Navigate to="/meli/dashboard" />}></Route>
        <Route path="/meli/dashboard" element={<Dashboard />}></Route>
        <Route path="/meli/login" element={<Login />}></Route>
        <Route path="/meli/orders" element={<OrderML />}></Route>
        <Route path="/meli/orders-created" element={<OrdersCreated />}></Route>
      </Routes>
    </div>
  );
}

export default App;
